<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="150px">
       <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item  label="主页页面标题信息"  prop="col1">
            <el-input v-model="form.mainPageTitle" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数据分区ID" prop="col2">
            <el-input v-model="form.id" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item  label="配置1"  prop="col1">
            <el-input v-model="form.col1" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="配置2" prop="col2">
            <el-input v-model="form.col2" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item  label="配置3"  prop="col3">
            <el-input v-model="form.col3" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item  label="配置4" prop="col4">
            <el-input v-model="form.col4" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="配置5" prop="col5">
            <el-input v-model="form.col5" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="配置6" prop="col6">
            <el-input v-model="form.col6" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="配置7" prop="col7">
            <el-input v-model="form.col7" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="配置8" prop="col8">
            <el-input v-model="form.col8" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="配置9" prop="col9">
            <el-input v-model="form.col9" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="配置10" prop="col10">
            <el-input v-model="form.col10" placeholder="" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center;">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      titleName: '',
      form: {},
      rules: {
        id: [{ required: true, message: '请输数据分区ID', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function(item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          //然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['saveGlobalEvn']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加抬头'
      } else {
        this.titleName = '修改抬头'
        this.itemData = JSON.parse(this.$route.query.item)
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          mainPageTitle: this.itemData.mainPageTitle,
          id: this.itemData.id,
          col1: this.itemData.col1,
          col2: this.itemData.col2,
          col3: this.itemData.col3,
          col4: this.itemData.col4,
          col5: this.itemData.col5,
          col6: this.itemData.col6,
          col7: this.itemData.col7,
          col8: this.itemData.col8,
          col9: this.itemData.col9,
          col10: this.itemData.col10,
        }
      }
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          if (this.routeType == 'add'){
            params.addNew = true
          }else{
            params.addNew = false
          }
          let server = 'saveGlobalEvn'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '保存成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  h4 {
    height: 40px;
    line-height: 40px;
    color: #f26333;
    padding-left: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
